import { useEffect, useReducer, useCallback, useMemo } from 'react';
// utils
import endpoints from 'src/api/endpoints';
import { useCookies } from 'react-cookie';
import { AuthContext } from './auth-context';
import axiosInstance from 'src/utils/axios-instanct';
import { Types as GlobalTypes } from 'src/generated/types';
import { isValidToken, removeAllCookie, setLocalCookie } from './utils';
import { ActionMapType, AuthStateType, AuthUserType } from '../../types';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
}

type Payload = {
  [Types.INITIAL]: {
    role: string | null;
    user: AuthUserType;
    child: string[];
    permissions: string[];
  };
  [Types.LOGIN]: {
    role: string;
    child: string[];
    user: AuthUserType;
    permissions: string[];
  };
  [Types.LOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  user: null,
  loading: true,
  child: [],
  permissions: [],
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      loading: false,
      child: action.payload.child,
      user: action.payload.user,
      permissions: action.payload.permissions,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      child: action.payload.permissions,
      user: action.payload.user,
      permissions: action.payload.permissions,
    };
  }

  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      user: null,
      child: [],
      permissions: [],
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';

type Props = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: Props) {
  const [cookies] = useCookies();
  const [state, dispatch] = useReducer(reducer, initialState);

  const csrfToken = useCallback(async () => {
    await axiosInstance.get(endpoints.csrf);
  }, []);

  const getMe = async () => {
    const res = await axiosInstance.get(endpoints.auth.me);
    const resRolesPermissions = await axiosInstance.get(endpoints.auth.permissions);

    dispatch({
      type: Types.INITIAL,
      payload: {
        user: res.data?.data,
        role: resRolesPermissions.data?.data?.role,
        child: resRolesPermissions.data?.data?.children,
        permissions: resRolesPermissions?.data?.data?.permissions,
      },
    });
  };

  const initialize = useCallback(async (_accessToken = null) => {
    try {
      const accessToken = _accessToken || cookies['accessToken'];
      if (accessToken && isValidToken(accessToken)) {
        setLocalCookie(accessToken, 31622400);
        getMe();
      } else {
        dispatch({
          type: Types.INITIAL,
          payload: {
            role: null,
            child: [],
            user: null,
            permissions: [],
          },
        });
      }
    } catch (error) {
      dispatch({
        type: Types.INITIAL,
        payload: {
          role: null,
          child: [],
          user: null,
          permissions: [],
        },
      });
    }
  }, []);

  useEffect(() => {
    csrfToken();
    initialize();
  }, [initialize, csrfToken]);

  // LOGIN
  const login = useCallback(async (username: string, password: string) => {
    const data = {
      username,
      password,
    };

    const res = await axiosInstance.post(endpoints.auth.login, data);

    const { access_token: accessToken } = res.data;
    setLocalCookie(accessToken, 31622400);
    initialize(accessToken);
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    removeAllCookie();
    dispatch({
      type: Types.LOGOUT,
    });
  }, []);

  const hasPermission = useCallback(
    (permission: GlobalTypes.PermissionType) => {
      return (
        state.permissions &&
        state.permissions.length > 0 &&
        state?.permissions?.includes(permission)
      );
    },
    [state?.permissions]
  );

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      child: state.child,
      role: state?.user?.role,
      permissions: state?.permissions,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      login,
      logout,
      hasPermission,
    }),
    [login, logout, hasPermission, state.user, status]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
