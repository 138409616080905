import { update } from 'lodash';

export default {
  csrf: '/sanctum/csrf-cookie',
  auth: {
    me: '/api/me',
    login: '/api/login',
    logout: '/api/logout',
    permissions: '/api/me/permission',
  },
  customer: {
    root: (id?: any) => `/api/customer/${String(id)}`,
    update: (id?: any) => `/api/customer/${String(id)}`,
    list: '/api/customer',
    create: '/api/customer',
    upload: 'api/image/upload',
  },
  pawn: {
    root: (id?: any) => `/api/pawn/${String(id)}`,
    list: '/api/pawn',
    create: '/api/pawn',
    createType: '/api/pawn/type',
    type: '/api/pawn/type',
    total: '/api/pawn/total',
    payment: `/api/pawn/pay`,
    delete: (id?: any) => `/api/pawn/${String(id)}`,
    forfeite: (id?: any) => `/api/pawn/${String(id)}`,
    reminder: '/api/pawn/reminder',
    notification: '/api/pawn/notification',
    calculate: '/api/pawn/calculate',
  },
  report: {
    user: '/api/report',
    userTotal: '/api/report/total',
    branch: '/api/report/branch',
    branchTotal: '/api/report/branch/total',
    repayment: '/api/report/repayment',
    repaymentTotal: '/api/report/repayment/total',
    transfer: '/api/transaction/transfer',
    expend: '/api/transaction/expend',
    repaymentCount: '/api/report/repayment/count',
  },
  user: {
    root: (id?: any) => `/api/user/${String(id)}`,
    update: (id?: any) => `/api/user/${String(id)}`,
    list: '/api/user',
    create: '/api/user',
  },
  branch: {
    root: (id?: any) => `/api/branch/${String(id)}`,
    update: (id?: any) => `/api/branch/${String(id)}`,
    log: `/api/branch/log`,
    list: '/api/branch',
    create: '/api/branch',
  },
  operation: {
    deposit: '/api/user/operation/deposit',
    withdraw: '/api/user/operation/withdraw',
    topup: '/api/user/operation/topup',
    transfer: '/api/user/operation/transfer',
    expend: '/api/user/operation/expend',
  },
  logs: {
    pawn: (id?: any) => `/api/log/pawn/${String(id)}`,
    branch: (id?: any) => `/api/log/branch/${String(id)}`,
    branchTotal: (id?: any) => `/api/log/branch/total/${String(id)}`,
    user: (id?: any) => `/api/log/user/${String(id)}`,
    userTotal: (id?: any) => `/api/log/user/total/${String(id)}`,
  },
};
